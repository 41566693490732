import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import logo from '../assets/logo.png'
import { Navbar, Nav, Button } from 'react-bootstrap';

// const user = "Z003VEYR - Mohanraju AC"
const help = "https://confluence.mindsphere-tools.siemens.cloud/"

class Header extends Component {    

    signOut = () =>  {
      Auth.signOut().then(() => {
        this.setState({authState: 'signIn'});
      }).catch(e => {
        console.log(e);
      });
    }
    render() {
        const user = this.props.user
        return (
        <>
          <Navbar bg="light" variant="light">
            <Navbar.Brand href="https://siemens.com/news">
              <img
                alt=""
                src={logo}
                width="140"
                height="19"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-center">
            <Navbar.Text>AWS Access Manager</Navbar.Text>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>{user}</Navbar.Text>
              <Button variant="link" onClick={this.signOut}> Sign Out </Button>
              <Nav.Link href={help}>help</Nav.Link>
            </Navbar.Collapse>
          </Navbar>
        </>
        );
    }
}


export default Header;