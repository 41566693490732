import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
// import FormControl from 'react-bootstrap/FormControl';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { API } from 'aws-amplify';
import AlertDismissible from './AlertDismissible'
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'

class AcctMgmt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            accountsList: [],
            account_group : null,
            selectedAction: "addAccount",
            selectedAccount: null,
            selectedAccountGroup: null,
            show : false,
            isLoading : false,
            isError : false,
            addAccountStatus: null,
            removeAccountStatus: null,
            isAccountDisabled: true,
            isAccountIdDisabled: false,
            isAccountNameDisabled: false,
            isAccountDescDisabled: false,
            isEnvDisabled: false,
            selectedAccountId: null,
            selectedAccountName: null,
            selectedAccountDesc: null,
            selectedEnv:null,
            errors: {}
        };
    }
    handleLoading = () => {
        this.setState({isLoading:!this.state.isLoading})
    }

    handleError = () => {
        this.setState({isError:!this.state.isError})
    }

    getAccountDict = (account) => {
        var dict = {value : account.account_id, label : account.account_id.concat(" - ",account.account_name)}
        return dict;
    }

    getDict = (item) => {
        var dict = {value : item, label : item} 
        return dict;
    }


    handleAccountGroupChange = async(accountgroup) => {
        this.handleLoading()

        this.setState({selectedAccountGroup:accountgroup})
        this.setState({selectedAccount:null})
        this.setState({accounts:null})
        this.setState({accountsList:null})


        let apiName = 'awstvm';
        //let path = '/dev/accounts';
        let path = '/prod/accounts';
        
        let myInit = { 
            queryStringParameters: {
            account_group: accountgroup.value
          }
        }
        let accountsList = [];

        await API.get(apiName, path, myInit).then(
            response => {
                this.setState({accounts : response});
                accountsList = response.map(this.getAccountDict)
                this.setState({accountsList : accountsList})
                this.handleLoading()
            }
            )
        .catch(error => {
            console.log(error)
            if(error)
                if(error.response)
                if(error.response.status)
                    this.setState({getAccountStatus: error.response.status});
            this.handleLoading()
            this.handleError()
            }
            )
    }

    handleAccountChange = async(account) => {
        this.setState({selectedAccount: account})
    }

    handleActionChange = async(action) => {

        
        this.setState({isAccountDisabled:!this.state.isAccountDisabled})
        this.setState({isAccountIdDisabled:!this.state.isAccountIdDisabled})
        this.setState({isAccountNameDisabled:!this.state.isAccountNameDisabled})
        this.setState({isAccountDescDisabled:!this.state.isAccountDescDisabled})
        this.setState({isEnvDisabled:!this.state.isEnvDisabled})


        this.setState({selectedAction:action})
        this.setState({selectedAccount:null})
        this.setState({selectedAccountGroup:null})
        this.setState({selectedAccountId:null})
        this.setState({selectedAccountName:null})
        this.setState({selectedAccountDesc:null})
        this.setState({selectedEnv:null})
        this.setState({accounts:null})
        this.setState({accountsList:null})
        this.setState({errors:{}})

    }


    validate(){
        let errors = {};
        let isValid = true;

        if(!this.state.selectedAccountGroup)
        {
            isValid = false;
            errors["accountGroup"] = "Please select the account group.";
        }
        if (this.state.selectedAction === "removeAccount")
        {
            if(!this.state.selectedAccount)
            {
                isValid = false;
                errors["account"] = "Please select the account.";
            }

        }
        else
        {
            if(!this.state.selectedAccountId)
            {
                isValid = false;
                errors["accountid"] = "Please enter the Account ID.";
            }
            if(!this.state.selectedAccountName)
            {
                isValid = false;
                errors["accountname"] = "Please enter the Account Name.";
            }
            if(!this.state.selectedAccountDesc)
            {
                isValid = false;
                errors["accountdesc"] = "Please enter the Account Desc.";
            }
            if(!this.state.selectedEnv)
            {
                isValid = false;
                errors["env"] = "Please enter the Account environment.";
            }
        }
        this.setState({
            errors: errors
          });

        console.log("isValid " + isValid)
        console.log(this.state.selectedAccountId + " " + this.state.selectedAccountName + this.state.selectedAccountDesc + this.state.selectedEnv)

        return isValid
    }


    handleSubmit = async(event) => {
        event.preventDefault();

        if(this.validate()){

            this.handleLoading()

            let apiName = 'awstvm';
            //let path = '/dev/accounts';
            let path = '/prod/accounts';
            console.log("in submit" + this.state.selectedAction )
            if (this.state.selectedAction === "addAccount")
            {
                console.log("in put account")
                let myInit = {
                    body: {
                        'account_id': this.state.selectedAccountId,
                        'account_name': this.state.selectedAccountName,
                        'account_desc': this.state.selectedAccountDesc,
                        'env': this.state.selectedEnv,
                        'account_group': this.state.selectedAccountGroup.value
                    }
                    }
                await API.put(apiName, path, myInit).then(
                    response => {
                        this.setState({addAccountStatus : 200});
                        this.handleLoading()
                    }
                    )
                .catch(error => {
                    console.log(error)
                    if(error)
                        if(error.response)
                        if(error.response.status)
                            this.setState({addAccountStatus: error.response.status});
                    this.handleLoading()
                    this.handleError()
                    }
                )
            }
            else{
                let myInit = { 
                        queryStringParameters: {
                            account_id: this.state.selectedAccount.value
                    }
                    }
                await API.del(apiName, path, myInit).then(
                    response => {
                        this.setState({removeAccountStatus : 200});
                        this.handleLoading()
                    }
                    )
                .catch(error => {
                    console.log(error)
                    if(error)
                        if(error.response)
                        if(error.response.status)
                            this.setState({removeAccountStatus: error.response.status});
                    this.handleLoading()
                    this.handleError()
                    }
                )
            }
        }   
    }

    render() {
        const { accounts_group} = this.props;
        const { errors, selectedAccount, selectedAccountGroup, selectedAccountId, selectedAccountName, accountsList, addAccountStatus, removeAccountStatus, isAccountDisabled,isAccountIdDisabled, isAccountNameDisabled, isAccountDescDisabled,isEnvDisabled } = this.state;
        let account_groupList = []
        account_groupList = accounts_group.map(this.getDict)

        if (addAccountStatus === 200)
        {
            const message = "Added account " + selectedAccountId + " - " + selectedAccountName
            return <AlertDismissible message={message} variant="success" header="Success" resetAlert={this.resetAlert}></AlertDismissible>
        }
        else if (addAccountStatus === 409)
        {
            const message = "Account already exists " + selectedAccountId + " - " + selectedAccountName
            return <AlertDismissible message={message} variant="warning" header="Warning" resetAlert={this.resetAlert}></AlertDismissible>
        }
        else if (removeAccountStatus === 200)
        {
            const message = "Removed the account " + selectedAccount.label
            return <AlertDismissible message={message} variant="success" header="Success" resetAlert={this.resetAlert}></AlertDismissible>
        }
        else if (addAccountStatus === 500 || removeAccountStatus === 500)
        {
            const message = "Server error while trying to add/remove account.Please try again later"
            return <AlertDismissible message={message} variant="danger" header="Error"></AlertDismissible>
        }
        else if (addAccountStatus != null || removeAccountStatus != null)
        {
            const message = "Something went wrong... Please contact your administrator"
            return <AlertDismissible message={message} variant="danger" header="Error"></AlertDismissible>
        }
        return (

            <Form onSubmit={this.handleSubmit}>
                <br />
                <ToggleButtonGroup type="radio" name="options" defaultValue={"addAccount"} onChange={this.handleActionChange}>
                    <ToggleButton value={"addAccount"}>Add Account</ToggleButton>
                    <ToggleButton value={"removeAccount"}>Remove Account</ToggleButton>
                </ToggleButtonGroup>
                <br />
                <div>
                    <Modal
                        show={this.state.isLoading}
                        onHide={this.handleLoading}
                        size="sm"
                        dialogClassName="modal-5w"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                    <Modal.Body>
                        <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Modal.Body>
                </Modal>
                </div>
                <br />
                <br />
                <Form.Group as={Row} controlId="accountgroup">
                    <Form.Label column sm={2}>
                    AccountGroup
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="accountgroup-select"
                        autoFocus
                        options={account_groupList}
                        simpleValue
                        clearable={true}
                        name="selected-accountgroup"
                        value={selectedAccountGroup}
                        onChange={this.handleAccountGroupChange}
                    />
                    <div className="text-danger">{errors.accountGroup}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="account">
                    <Form.Label column sm={2}>
                    Account
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="account-select"
                        autoFocus
                        options={accountsList}
                        simpleValue
                        name="selected-account"
                        isDisabled={isAccountDisabled}
                        value={selectedAccount}
                        onChange={this.handleAccountChange}
                    />
                    <div className="text-danger">{errors.account}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="accountid">
                    <Form.Label column sm={2}>
                    Account ID
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="number" maxLength={8}  placeholder="Account ID"  disabled={isAccountIdDisabled} onChange={e => this.setState({ selectedAccountId: e.target.value })}/>
                    <div className="text-danger">{errors.gid}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="accountname">
                    <Form.Label column sm={2}>
                    Account Name
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="text" maxLength={100} placeholder="Account Name"  disabled={isAccountNameDisabled} onChange={e => this.setState({ selectedAccountName: e.target.value })}/>
                    <div className="text-danger">{errors.email}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="accountdesc">
                    <Form.Label column sm={2}>
                    Account Description
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="text" maxLength={100} placeholder="Account Description"  disabled={isAccountDescDisabled} onChange={e => this.setState({ selectedAccountDesc: e.target.value })}/>
                    <div className="text-danger">{errors.fname}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="env">
                    <Form.Label column sm={2}>
                    Environment
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="text" maxLength={100} placeholder="PROD or DEV"  disabled={isEnvDisabled} onChange={e => this.setState({ selectedEnv: e.target.value })}/>
                    <div className="text-danger">{errors.lname}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col sm={{ span: 10, offset: 2 }}>
                    <Button type="submit">Submit</Button>
                    </Col>
                </Form.Group>
                </Form>
        )
    }
}

export default AcctMgmt;