import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { API } from 'aws-amplify';
import AlertDismissible from './AlertDismissible'
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'

class UserMgmt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            usersList: [],
            user_group : null,
            selectedAction: "addUser",
            selectedUser: null,
            selectedUserGroup: null,
            show : false,
            isLoading : false,
            isError : false,
            addUserStatus:null,
            removeUserStatus:null,
            isUserDisabled: true,
            isEmailDisabled: false,
            isFNameDisabled: false,
            isLNameDisabled: false,
            selectedGID: null,
            selectedEmail:null,
            selectedFName: null,
            selectedLName:null,
            errors: {}
        };
    }
    handleLoading = () => {
        this.setState({isLoading:!this.state.isLoading})
    }

    handleError = () => {
        this.setState({isError:!this.state.isError})
    }

    getUserDict = (user) => {
        var dict = {value : user.gid, label : user.gid.concat(" - ",user.email)} 

        return dict;
    }

    getDict = (item) => {
        var dict = {value : item, label : item} 
        return dict;
    }


    handleUserGroupChange = async(usergroup) => {
        this.handleLoading()
        this.setState({selectedUserGroup:usergroup})

        this.setState({selectedUser:null})

        this.setState({users:null})
        this.setState({usersList:null})


        let apiName = 'awstvm';
        //let path = '/dev/users';
        let path = '/prod/users';
        
        let myInit = { 
            queryStringParameters: {
            user_group: usergroup.value
          }
        }
        let usersList = [];

        await API.get(apiName, path, myInit).then(
            response => {
                this.setState({users : response});
                usersList = response.map(this.getUserDict)
                this.setState({usersList : usersList})
                this.handleLoading()
            }
            )
        .catch(error => {
            console.log(error)
            if(error)
                if(error.response)
                if(error.response.status)
                    this.setState({getUserStatus: error.response.status});
            this.handleLoading()
            this.handleError()
            }
            )
    }

    handleUserChange = async(user) => {
        this.setState({selectedUser: user})
    }

    handleActionChange = async(action) => {

        
        this.setState({isUserDisabled:!this.state.isUserDisabled})
        this.setState({isGIDDisabled:!this.state.isGIDDisabled})
        this.setState({isEmailDisabled:!this.state.isEmailDisabled})
        this.setState({isFNameDisabled:!this.state.isFNameDisabled})
        this.setState({isLNameDisabled:!this.state.isLNameDisabled})


        this.setState({selectedAction:action})
        this.setState({selectedUser:null})
        this.setState({selectedUserGroup:null})
        this.setState({users:null})
        this.setState({usersList:null})
        this.setState({usersList:null})
        this.setState({accountsList:null})
        this.setState({roles:null})
        this.setState({rolesList:null})
        this.setState({errors:{}})

    }


    validate(){
        let errors = {};
        let isValid = true;
        if(!this.state.selectedUserGroup)
        {
            isValid = false;
            errors["userGroup"] = "Please select the user group.";
        }
        if (this.state.selectedAction === "removeUser")
        {
            if(!this.state.selectedUser)
            {
                isValid = false;
                errors["user"] = "Please select the user.";
            }

        }
        else
        {
            if(!this.state.selectedGID)
            {
                isValid = false;
                errors["gid"] = "Please enter the GID.";
            }
            if(!this.state.selectedEmail)
            {
                isValid = false;
                errors["email"] = "Please enter the email.";
            }
            if(!this.state.selectedFName)
            {
                isValid = false;
                errors["fname"] = "Please enter the First Name.";
            }
            if(!this.state.selectedLName)
            {
                isValid = false;
                errors["lname"] = "Please enter the Last Name.";
            }
        }
        this.setState({
            errors: errors
          });

        return isValid
    }


    handleSubmit = async(event) => {
        event.preventDefault();

        if(this.validate()){

            this.handleLoading()

            let apiName = 'awstvm';
            //let path = '/dev/user';
            let path = '/prod/user';
            if (this.state.selectedAction === "addUser")
            {
                let myInit = {
                    body: {
                        'gid': this.state.selectedGID,
                        'email': this.state.selectedEmail,
                        'first_name': this.state.selectedFName,
                        'last_name': this.state.selectedLName,
                        'user_group': this.state.selectedUserGroup.value
                    }
                    }
                await API.put(apiName, path, myInit).then(
                    response => {
                        this.setState({addUserStatus : 200});
                        this.handleLoading()
                    }
                    )
                .catch(error => {
                    console.log(error)
                    if(error)
                        if(error.response)
                        if(error.response.status)
                            this.setState({addUserStatus: error.response.status});
                    this.handleLoading()
                    this.handleError()
                    }
                )
            }
            else{
                let myInit = { 
                        queryStringParameters: {
                            gid: this.state.selectedUser.value
                    }
                    }
                await API.del(apiName, path, myInit).then(
                    response => {
                        this.setState({removeUserStatus : 200});
                        this.handleLoading()
                    }
                    )
                .catch(error => {
                    console.log(error)
                    if(error)
                        if(error.response)
                        if(error.response.status)
                            this.setState({removeUserStatus: error.response.status});
                    this.handleLoading()
                    this.handleError()
                    }
                )
            }
        }   
    }

    render() {
        const { users_group} = this.props;
        const { errors, selectedUser, selectedUserGroup, usersList, addUserStatus, removeUserStatus, isUserDisabled,isGIDDisabled, isEmailDisabled, isFNameDisabled,isLNameDisabled, selectedGID, selectedEmail } = this.state;
        let users_groupList = []
        users_groupList = users_group.map(this.getDict)

        if (addUserStatus === 200)
        {
            const message = "Added user " + selectedGID + " - " + selectedEmail
            return <AlertDismissible message={message} variant="success" header="Success" resetAlert={this.resetAlert}></AlertDismissible>
        }
        else if (addUserStatus === 409)
        {
            const message = "User already exists " + selectedGID + " - " + selectedEmail
            return <AlertDismissible message={message} variant="warning" header="Warning" resetAlert={this.resetAlert}></AlertDismissible>
        }
        else if (removeUserStatus === 200)
        {
            const message = "Offboarded the user " + selectedUser.label
            return <AlertDismissible message={message} variant="success" header="Success" resetAlert={this.resetAlert}></AlertDismissible>
        }
        else if (addUserStatus === 500 || removeUserStatus === 500)
        {
            const message = "Server error while trying to add/remove user.Please try again later"
            return <AlertDismissible message={message} variant="danger" header="Error"></AlertDismissible>
        }
        else if (addUserStatus != null || removeUserStatus != null)
        {
            const message = "Something went wrong... Please contact your administrator"
            return <AlertDismissible message={message} variant="danger" header="Error"></AlertDismissible>
        }
        return (

            <Form onSubmit={this.handleSubmit}>
                <br />
                <ToggleButtonGroup type="radio" name="options" defaultValue={"addUser"} onChange={this.handleActionChange}>
                    <ToggleButton value={"addUser"}>Onboard User</ToggleButton>
                    <ToggleButton value={"removeUser"}>Offboard User</ToggleButton>
                </ToggleButtonGroup>
                <br />
                <div>
                    <Modal
                        show={this.state.isLoading}
                        onHide={this.handleLoading}
                        size="sm"
                        dialogClassName="modal-5w"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                    <Modal.Body>
                        <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Modal.Body>
                </Modal>
                </div>
                <br />
                <br />
                <Form.Group as={Row} controlId="user">
                    <Form.Label column sm={2}>
                    UserGroup
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="usergroup-select"
                        autoFocus
                        options={users_groupList}
                        simpleValue
                        clearable={true}
                        name="selected-usergroup"
                        value={selectedUserGroup}
                        onChange={this.handleUserGroupChange}
                    />
                    <div className="text-danger">{errors.userGroup}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="user">
                    <Form.Label column sm={2}>
                    User
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="user-select"
                        autoFocus
                        options={usersList}
                        simpleValue
                        name="selected-user"
                        isDisabled={isUserDisabled}
                        value={selectedUser}
                        onChange={this.handleUserChange}
                    />
                    <div className="text-danger">{errors.user}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="user">
                    <Form.Label column sm={2}>
                    GID
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="text" maxLength={8} pattern="^[a-zA-Z0-9]*$" placeholder="Enter GID"  disabled={isGIDDisabled} onChange={e => this.setState({ selectedGID: e.target.value })}/>
                    <div className="text-danger">{errors.gid}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="user">
                    <Form.Label column sm={2}>
                    Email
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="email" maxLength={100} placeholder="Enter Email"  disabled={isEmailDisabled} onChange={e => this.setState({ selectedEmail: e.target.value })}/>
                    <div className="text-danger">{errors.email}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="user">
                    <Form.Label column sm={2}>
                    First Name
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="text" maxLength={100} placeholder="First Name"  disabled={isFNameDisabled} onChange={e => this.setState({ selectedFName: e.target.value })}/>
                    <div className="text-danger">{errors.fname}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="user">
                    <Form.Label column sm={2}>
                    Last Name
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="text" maxLength={100} placeholder="Last Name"  disabled={isLNameDisabled} onChange={e => this.setState({ selectedLName: e.target.value })}/>
                    <div className="text-danger">{errors.lname}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col sm={{ span: 10, offset: 2 }}>
                    <Button type="submit">Submit</Button>
                    </Col>
                </Form.Group>
                </Form>
        )
    }
}

export default UserMgmt;