import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
// import FormControl from 'react-bootstrap/FormControl';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { API } from 'aws-amplify';
import AlertDismissible from './AlertDismissible'

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'


class AccessMgmt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            usersList: [],
            accounts: [],
            accountsList: [],
            roles: [],
            rolesList: [],
            user_group : null,
            selectedAction: "addAccess",
            selectedUser: null,
            selectedUserGroup: null,
            selectedAccountGroup: null,
            selectedAccount: null,
            selectedRole:null,
            account_group: null,
            show : false,
            isLoading : false,
            isError : false,
            getUserStatus : null,
            getAccountStatus : null,
            getRoleStatus:null,
            addAccessStatus:null,
            removeAccessStatus:null,
            errors: {}
        };
    }
    handleLoading = () => {
        this.setState({isLoading:!this.state.isLoading})
    }

    handleError = () => {
        this.setState({isError:!this.state.isError})
    }

    getUserDict = (user) => {
        // var dict = {value : user.gid.concat(" - ",user.email), label : user.gid.concat(" - ",user.email)} 
        var dict = {value : user.gid, label : user.gid.concat(" - ",user.email)} 

        return dict;
    }

    getDict = (item) => {
        var dict = {value : item, label : item} 
        return dict;
    }

    getAccountDict = (account) => {
        // var dict = {value : account.account_id.concat(" - ",account.account_name), label : account.account_id.concat(" - ",account.account_name)} 
        var dict = {value : account.account_id, label : account.account_id.concat(" - ",account.account_name)}
        return dict;
    }

    handleUserGroupChange = async(usergroup) => {
        // console.log(ReactDOM.findDOMNode(this.selectusergroup).value)
        // this.setState({selectedUserGroup:usergroup.value})
        this.handleLoading()

        this.setState({selectedUserGroup:usergroup})

        this.setState({selectedUser:null})
        this.setState({selectedAccountGroup:null})
        this.setState({selectedAccount:null})
        this.setState({selectedRole:null})

        this.setState({users:null})
        this.setState({usersList:null})
        this.setState({accounts:null})
        this.setState({accountsList:null})
        this.setState({roles:null})
        this.setState({rolesList:null})

        let apiName = 'awstvm';
        //let path = '/dev/users';
        let path = '/prod/users';
        
        let myInit = { 
            // headers: { 'Cache-Control: no-store' },
            // headers: { "cache-control": "max-age=0" },
            // custom_header: async () => {
            //     return { "Cache-Control": "max-age=0" }
            //   },
            queryStringParameters: {
            //   user_group: ReactDOM.findDOMNode(this.selectusergroup).value
            user_group: usergroup.value
          }

        }
        let usersList = [];

        await API.get(apiName, path, myInit).then(
            response => {
                this.setState({users : response});
                usersList = response.map(this.getUserDict)
                this.setState({usersList : usersList})
                this.handleLoading()
            }
            )
        .catch(error => {
            console.log(error)
            if(error)
                if(error.response)
                if(error.response.status)
                    this.setState({getUserStatus: error.response.status});
            this.handleLoading()
            this.handleError()
            }
            )
    }

    handleAccountGroupChange = async(accountgroup) => {
        // console.log(ReactDOM.findDOMNode(this.selectusergroup).value)
        this.handleLoading()

        this.setState({selectedAccountGroup:accountgroup})
        this.setState({selectedAccount:null})
        this.setState({accounts:null})
        this.setState({accountsList:null})

        let apiName = 'awstvm';
        //let path = '/dev/accounts';
        let path = '/prod/accounts';
        // const ms = Date.now();

        if (this.state.selectedAction === "addAccess")
        {
            var myInit = { 
                // headers: { 'Cache-Control' : 'no-store' },
                queryStringParameters: {
                account_group: accountgroup.value
                // ,
                // ts: ms
            }
            }
        }
        else
        {
             myInit = {
                // headers: { 'Cache-Control' : 'no-store' },
                queryStringParameters: {
                account_group: accountgroup.value,
                gid: this.state.selectedUser.value
                // ,
                // ts: ms
            }
            }
        }
        let accountsList = [];

        await API.get(apiName, path, myInit).then(
            response => {
                this.setState({accounts : response});
                accountsList = response.map(this.getAccountDict)
                this.setState({accountsList : accountsList})
                // console.log(accountsList)
                this.handleLoading()
            }
            )
        .catch(error => {
            console.log(error)
            if(error)
                if(error.response)
                if(error.response.status)
                    this.setState({getAccountStatus: error.response.status});
            this.handleLoading()
            this.handleError()
            }
            )
    }

    handleAccountChange = async(account_id) => {
        this.handleLoading()

        // console.log(ReactDOM.findDOMNode(this.selectusergroup).value)

        this.setState({selectedAccount:account_id})
        this.setState({selectedRole:null})
        this.setState({roles:null})
        this.setState({rolesList:null})

        let apiName = 'awstvm';
        //let path = '/dev/role';
        let path = '/prod/role';
        // const ms = Date.now();
        if (this.state.selectedAction === "addAccess")
        {
            var myInit = { 
                // headers: { 'Cache-Control' : 'no-store' },
                queryStringParameters: {
                account_id: account_id.value
                // ,
                // ts: ms
              }
            }
        }
        else
        {
            myInit = { 
                // headers: { 'Cache-Control' : 'no-store' },
                queryStringParameters: {
                account_id: account_id.value,
                gid: this.state.selectedUser.value
                // ,
                // ts: ms
              }
            }
        }

        let rolesList = [];

        await API.get(apiName, path, myInit).then(
            response => {
                this.setState({roles : response});
                rolesList = response.map(this.getDict)
                this.setState({rolesList : rolesList})
                // console.log(rolesList)
                this.handleLoading()
            }
            )
        .catch(error => {
            console.log(error)
            if(error)
                if(error.response)
                if(error.response.status)
                    this.setState({getRoleStatus: error.response.status});
            this.handleLoading()
            this.handleError()
            }
            )
    }

    handleUserChange = async(user) => {
        
        // console.log(user)
        this.setState({selectedUser: user})
        this.setState({selectedAccountGroup:null})
        this.setState({selectedAccount:null})
        this.setState({selectedRole:null})
        this.setState({accounts:null})
        this.setState({accountsList:null})
        this.setState({roles:null})
        this.setState({rolesList:null})
        // console.log(user.value)

        // console.log(this.state.selectedUser)
    }

    handleRoleChange = async(role) => {
        this.setState({selectedRole:role})
    }

    handleActionChange = async(action) => {

        this.setState({selectedAction:action})
        // console.log(action)
        this.setState({selectedUser:null})
        this.setState({selectedUserGroup:null})
        this.setState({selectedAccountGroup:null})
        this.setState({selectedAccount:null})
        this.setState({selectedRole:null})
        this.setState({users:null})
        this.setState({usersList:null})
        this.setState({accounts:null})
        this.setState({accountsList:null})
        this.setState({roles:null})
        this.setState({rolesList:null})
        this.setState({errors:{}})

        // this.setState({users_groupList:null})

        // console.log(e.currentTarget.value)
    }
    validate(){
        let errors = {};
        let isValid = true;

        if(!this.state.selectedUser)
        {
            isValid = false;
            errors["user"] = "Please select the user.";
        }

        if(!this.state.selectedUserGroup)
        {
            isValid = false;
            errors["userGroup"] = "Please select the user group.";
        }

        if(!this.state.selectedAccountGroup)
        {
            isValid = false;
            errors["accountGroup"] = "Please select the account group.";
        }
        if(!this.state.selectedAccount)
        {
            isValid = false;
            errors["account"] = "Please select the account.";
        }
        if(!this.state.selectedRole)
        {
            isValid = false;
            errors["role"] = "Please select the role.";
        }
        this.setState({
            errors: errors
          });
        
        return isValid
    }


    handleSubmit = async(event) => {

        event.preventDefault();
        // this.setState({selectedUser:null})
        // this.setState({selectedUserGroup:null})
        // this.setState({selectedAccountGroup:null})
        // this.setState({selectedAccount:null})
        // this.setState({selectedRole:null})


        // console.log(this.state.selectedUser.value,this.state.selectedAccount.value,this.state.selectedRole.value)


        // let myInit = { 
        // //     queryStringParameters: {
        // //     account_id: this.state.selectedAccount.value,
        // //     role: this.state.selectedRole.value,
        // //     gid: this.state.selectedUser.value,
        // //     path: "/siemens"
        // //   }
        // body: {
        //     account_id: this.state.selectedAccount.value,
        //     role: this.state.selectedRole.value,
        //     gid: this.state.selectedUser.value,
        //     path: "/siemens"
        //   }
        // }
        if(this.validate()){

            this.handleLoading()
        
            this.setState({users:null})
            this.setState({usersList:null})
            this.setState({accounts:null})
            this.setState({accountsList:null})
            this.setState({roles:null})
            this.setState({rolesList:null})
            this.setState({errors:{}})


            let apiName = 'awstvm';
            //let path = '/dev/access';
            let path = '/prod/access';

            if (this.state.selectedAction === "addAccess")
            {
                let myInit = {
                    body: {
                        'account_id': this.state.selectedAccount.value,
                        'role': this.state.selectedRole.value,
                        'gid': this.state.selectedUser.value
                        // ,
                        // path: "/siemens"
                    }
                    }
                await API.put(apiName, path, myInit).then(
                    response => {
                        this.setState({addAccessStatus : 200});
                        console.log("add access status:" + response.status)
                        console.log("add access response:" + response)
                        this.handleLoading()
                    }
                    )
                .catch(error => {
                    console.log(error)
                    if(error)
                        if(error.response)
                        if(error.response.status)
                            this.setState({addAccessStatus: error.response.status});
                    this.handleLoading()
                    this.handleError()
                    }
                )
            }
            else{
                let myInit = { 
                        queryStringParameters: {
                        account_id: this.state.selectedAccount.value,
                        role: this.state.selectedRole.value,
                        gid: this.state.selectedUser.value,
                        //path: "/siemens"
                        path: "/"
                    }
                    }
                // console.log(myInit)
                await API.del(apiName, path, myInit).then(
                    response => {
                        this.setState({removeAccessStatus : 200});
                        this.handleLoading()
                    }
                    )
                .catch(error => {
                    console.log(error)
                    if(error)
                        if(error.response)
                        if(error.response.status)
                            this.setState({removeAccessStatus: error.response.status});
                    this.handleLoading()
                    this.handleError()
                    }
                )
            }
    }
        // console.log("submit")
        // 
    }


    resetAlert = () => {
        console.log("In Reset Alert")
        this.setState({addAccessStatus:null})
        this.setState({removeAccessStatus:null})
        this.setState({selectedUser:null})
        this.setState({selectedUserGroup:null})
        this.setState({selectedAccountGroup:null})
        this.setState({selectedAccount:null})
        this.setState({selectedRole:null})
    }

    render() {
        const {accounts_group, users_group} = this.props;
        const { addAccessStatus, removeAccessStatus, selectedUser, selectedUserGroup, selectedAccountGroup, selectedAccount, selectedRole, usersList, accountsList, rolesList, errors } = this.state;
        let users_groupList = []
        users_groupList = users_group.map(this.getDict)
        let account_groupList = []
        account_groupList = accounts_group.map(this.getDict)

        console.log("***************")
        // console.log(addAccessStatus, selectedUser, selectedUserGroup, selectedAccountGroup, selectedAccount, selectedRole, usersList, accountsList, rolesList)
        // console.log(accounts_group, users_group)
        // console.log("addAccessStatus in render" + addAccessStatus)
        // let addAccessStatus=200
        // console.log("addAccessStatus in render" + addAccessStatus)
        if (addAccessStatus === 200)
        {
            const message = "Access granted to user " + selectedUser.label + " for role: " + selectedRole.value + " in the account " + selectedAccount.value
            return <AlertDismissible message={message} variant="success" header="Success" resetAlert={this.resetAlert}></AlertDismissible>
        }
        else if (addAccessStatus === 409)
        {
            const message = "User " + selectedUser.label + " already has access for the role: " + selectedRole.value + " in the account " + selectedAccount.value
            return <AlertDismissible message={message} variant="warning" header="Warning" resetAlert={this.resetAlert}></AlertDismissible>
        }       
        else if (removeAccessStatus === 200)
        {
            const message = "Access remove for user " + selectedUser.label + " for role: " + selectedRole.value + " in the account " + selectedAccount.value
            return <AlertDismissible message={message} variant="success" header="Success" resetAlert={this.resetAlert}></AlertDismissible>
        }
        else if (removeAccessStatus === 409)
        {
            const message = "Access remove for user " + selectedUser.label + " for role: " + selectedRole.value + " in the account " + selectedAccount.value
            return <AlertDismissible message={message} variant="warning" header="Warning" resetAlert={this.resetAlert}></AlertDismissible>
        }
        else if (addAccessStatus === 500 || removeAccessStatus === 500)
        {
            const message = "Server error while trying to add the access.Please try again later"
            return <AlertDismissible message={message} variant="danger" header="Error" resetAlert={this.resetAlert}></AlertDismissible>
        }        
        else if (addAccessStatus != null || addAccessStatus != null)
        {
            const message = "Something went wrong... Please contact your administrator"
            return <AlertDismissible message={message} variant="danger" header="Error" resetAlert={this.resetAlert}></AlertDismissible>
        }
        // else
        // {

        return (

            <Form onSubmit={this.handleSubmit}>
            <div>
                <Modal
                  show={this.state.isLoading}
                  onHide={this.handleLoading}
                  size="sm"
                  dialogClassName="modal-5w"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  >
                 <Modal.Body>
                 <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                 </Modal.Body>
               </Modal>
            </div>
                <br />
                <ToggleButtonGroup type="radio" name="options" defaultValue={"addAccess"} onChange={this.handleActionChange}>
                    <ToggleButton value={"addAccess"}>Add Access</ToggleButton>
                    <ToggleButton value={"removeAccess"}>Remove Access</ToggleButton>
                </ToggleButtonGroup>
                <br />
                {/* <Form.Check
                    type="radio"
                    label="Add Access"
                    name="access"
                    id="addaccess"
                    inline
                    defaultChecked
                /> 
                <Form.Check
                    type="radio"
                    label="Remove Access"
                    name="access"
                    id="removeaccess"
                    onChange={this.handleActionChange}
                    inline
                /> */}
                <br />
                <br />
                <Form.Group as={Row} controlId="user">
                    <Form.Label column sm={2}>
                    UserGroup
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="usergroup-select"
                        // ref={(ref) => { this.selectedUserGroup = ref; }}
                        // onSelectResetsInput={false}
                        autoFocus
                        options={users_groupList}
                        simpleValue
                        clearable={true}
                        name="selected-usergroup"
                        // disabled={this.state.disabled}
                        value={selectedUserGroup}
                        // defaultValue="MDSP"
                        onChange={this.handleUserGroupChange}
                        // rtl={this.state.rtl}
                    />
                    <div className="text-danger">{errors.userGroup}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="user">
                    <Form.Label column sm={2}>
                    User
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="user-select"
                        // ref={(ref) => { this.selectuser = ref; }}
                        // onSelectResetsInput={false}
                        autoFocus
                        options={usersList}
                        simpleValue
                        name="selected-user"
                        // disabled={this.state.disabled}
                        value={selectedUser}
                        onChange={this.handleUserChange}
                        // rtl={this.state.rtl}
                    />
                    <div className="text-danger">{errors.user}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="accountgroup">
                    <Form.Label column sm={2}>
                    AccountGroup
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="accountgroup-select"
                        // ref={(ref) => { this.selectaccountgroup = ref; }}
                        // onSelectResetsInput={false}
                        autoFocus
                        options={account_groupList}
                        simpleValue
                        name="selected-accountgroup"
                        // disabled={this.state.disabled}
                        value={selectedAccountGroup}
                        onChange={this.handleAccountGroupChange}
                        // rtl={this.state.rtl}
                    />
                    <div className="text-danger">{errors.accountGroup}</div>
                    </Col>
                </Form.Group>
   
                <Form.Group as={Row} controlId="accountid">
                    <Form.Label column sm={2}>
                    accountid
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="account-select"
                        // ref={(ref) => { this.selectedAccount = ref; }}
                        // onSelectResetsInput={false}
                        autoFocus
                        options={accountsList}
                        simpleValue
                        // clearable={this.state.clearable}
                        name="selected-account"
                        // disabled={this.state.disabled}
                        value={selectedAccount}
                        onChange={this.handleAccountChange}
                        // rtl={this.state.rtl}
                    />
                    <div className="text-danger">{errors.account}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="role">
                    <Form.Label column sm={2}>
                    role
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="role-select"
                        // ref={(ref) => { this.selectedRole = ref; }}
                        // onSelectResetsInput={false}
                        autoFocus
                        options={rolesList}
                        simpleValue
                        // clearable={this.state.clearable}
                        name="selected-role"
                        // disabled={this.state.disabled}
                        value={selectedRole}
                        onChange={this.handleRoleChange}
                        // rtl={this.state.rtl}
                    />
                    <div className="text-danger">{errors.role}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col sm={{ span: 10, offset: 2 }}>
                    <Button type="submit">Submit</Button>
                    </Col>
                </Form.Group>
                </Form>
        )
        }
    // }
}

export default AccessMgmt;